import React, { useState } from "react"

import { Accordion, Button } from "react-bootstrap"
import Open from "../../../../assets/icons/open_icon.svg"
import Close from "../../../../assets/icons/close_icon.svg"

import "./ListItems.css"

const ListItems = ({ elements, showModal }) => {
  const [keyCollapse, setKeyCollapse] = useState(-1)
  const handleCollapse = index => {
    if (keyCollapse === index) setKeyCollapse(-1)
    else setKeyCollapse(index)
  }
  const toggleElements = elements.map((item, index) => {
    const activeClass =
      index === keyCollapse ? ["separator", "active"] : ["separator"]
    const toggleClasses =
      index === keyCollapse ? ["list-item", "active"] : ["list-item"]
    return (
      <div
        key={index}
        className="d-flex flex-column justify-content-center align-items-start"
      >
        <Accordion.Toggle
          className={toggleClasses.join(" ")}
          as={Button}
          variant="link"
          eventKey={index}
          onKeyDown={() => null}
          onClick={() => {
            return item.description.length > 0 ? handleCollapse(index) : setKeyCollapse(-1)
          }}
        >
          <span>{item.title}</span>
          {item.description.length > 0 ? (
            index === keyCollapse ? (
              <Close />
            ) : (
              <Open />
            )
          ) : null}
        </Accordion.Toggle>
        <div className={activeClass.join(" ")}></div>
        <Accordion.Collapse eventKey={index} className="list-description">
          <div>
            {item.description.map((description, index) => (
              <p key={index}>{description}</p>
            ))}
            {item.description.length > 0 ? (
              <span
                role="button"
                tabIndex={0}
                onClick={showModal}
                onKeyDown={() => null}
              >
                Solicita tu cotización
              </span>
            ) : null}
          </div>
        </Accordion.Collapse>
      </div>
    )
  })
  return <Accordion defaultActiveKey={-1}>{toggleElements}</Accordion>
}

export default ListItems
