import React, { useState } from "react"
import { graphql } from 'gatsby'
import Nav from "../components/UI/Nav/Nav"
import { Container, Row } from "react-bootstrap"
import SectionsListItems from "../components/UI/SectionsListItems/SectionsListItems"
import HeadImage from "../components/UI/HeadImage/HeadImage"
import Modal from "../components/UI/Modal/Modal"
import Footer from "../components/UI/Footer/Footer"
import SEO from "../components/seo"

const ProductsPage = ({ data }) => {
  const [modalShow, setModalShow] = useState(false)
  const productsSections = []
  const images = [
    data.products1.childImageSharp.fluid.src,
    data.products2.childImageSharp.fluid.src,
  ]
  data.prismic.allProductoss.edges.map(items => {
    if (items.node.categorias in productsSections)
      productsSections[items.node.categorias].push({
        title: items.node.titulo[0].text,
        description: items.node.descripcion
          ? items.node.descripcion.map(item => item.text)
          : [],
      })
    else
      productsSections[items.node.categorias] = [
        {
          title: items.node.titulo[0].text,
          description: items.node.descripcion
            ? items.node.descripcion.map(item => item.text)
            : [],
        },
      ]
    return null
  })
  return (
    <Container fluid>
      <SEO />
      <Modal show={modalShow} handleClose={() => setModalShow(false)} />
      <Nav active="PRODUCTOS" />
      <Row>
        <HeadImage
          image={data.productsheader.childImageSharp.fluid}
          height="auto"
        >
          <p className="small-size">
            Contamos con los implementos necesarios para instalaciones de POE y
            pacientes deacuerdo a la nom. Nuestros materiales de blíndaje
            cumplen la composición y densidades exigidas o el equivalente de
            plomo especificado.
          </p>
        </HeadImage>
      </Row>
      <Row>
        <SectionsListItems
          sections={productsSections}
          images={images}
          showModal={() => setModalShow(true)}
        />
      </Row>
      <Footer />
    </Container>
  )
}

export default ProductsPage

export const pageQuery = graphql`
  query productsQuery {
    prismic {
      allProductoss {
        edges {
          node {
            categorias
            titulo
            descripcion
            _linkType
          }
        }
      }
    }
    productsheader: file(relativePath: { eq: "index.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    products1: file(relativePath: { eq: "products-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    products2: file(relativePath: { eq: "products-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
