import React from "react"
import "./BarSeparator.css"

const BarSeparator = ({ left, title, center }) => {
  const classesBar = left
    ? ["bar-separator", "bar-left"]
    : ["bar-separator", "bar-right"]
  return (
    <div className={classesBar.join(" ")}>
      <div className="arrow"></div>
      <div className="arrow-2"></div>
      <div className="arrow-3"></div>
      <h1 className={center ? 'text-center mx-2 mx-sm-0' : "mx-2 mx-sm-0"}>{title}</h1>
    </div>
  )
}

export default BarSeparator
