import React from "react"

import BarSeparator from "../BarSeparator/BarSeparator"

import { Container, Row, Col, Image } from "react-bootstrap"

const SectionsItems = ({ children, images, index, title }) => {
  const section = (
    <div key={index} className="w-100">
      <BarSeparator title={title} left={index % 2 !== 0} />
      {index % 2 !== 1 ? (
        <Container
          className="section-container d-flex justify-content-center"
          fluid
        >
          <Row
            className="text-align-center justify-content-center align-items-center w-100"
            style={{ maxWidth: "1600px" }}
          >
            <Col xs="12" md="5">
              {children}
            </Col>
            <Col
              xs="12"
              md="5"
              className="d-flex justify-content-center align-items-center mt-4 mt-sm-0"
            >
              <Image src={images[0]} alt="services" fluid />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          className="section-container d-flex justify-content-center"
          fluid
        >
          <Row
            className="text-align-center justify-content-center align-items-center w-100"
            style={{ maxWidth: "1600px" }}
          >
            <Col
              xs="12"
              md="5"
              className="d-flex justify-content-center align-items-center order-sm-1 order-3 mt-4 mt-sm-0"
            >
              <Image src={images[1]} alt="services" fluid />
            </Col>
            <Col xs="12" md="5" className="order-sm-3 order-1">
              {children}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
  return section
}

export default SectionsItems
