import React from "react"

import ListItems from "./ListItems/ListItems"
import SectionsItems from "../SectionsItems/SectionsItems"

const SectionsListItems = ({ sections, images, showModal }) => {
  const sectionsObjects = []
  Object.keys(sections).forEach(function (key, index) {
    sectionsObjects.push(
      <SectionsItems key={`SectionItems-${index}`} images={images} title={key} index={index}>
        <ListItems elements={sections[key]} showModal={showModal} />
      </SectionsItems>
    )
  })
  return sectionsObjects
}

export default SectionsListItems
